
.flyout {
  position: absolute;
  /* 3rem is header size*/
  top: calc(3rem - 0.25rem);
  width: 14rem;
  right: 1.5rem;
  background-color: $ui-01;

  @include box-shadow;
}

.flyout-dark {
  color: white;
  background-color: $gray-100;
}
