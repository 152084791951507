.login-form-container {
  min-width: 400px;
  background-color: $ui-01;
  padding: 1.5rem;

  @include box-shadow;

  form {
    width: 100%;
  }

  button {
    min-width: 100%;
  }
}
