// Third Party
@import 'carbon-components/scss/globals/scss/vars.scss';
@import 'carbon-components/scss/globals/scss/helper-mixins.scss';

// Pages
@import './pages/Login/Login.scss';

// Components
@import './components/LoginForm/LoginForm.scss';
@import './components/Flyout/Flyout.scss';
@import './components/UserProfileHeaderAction/UserProfileHeaderAction.scss';
@import './components/ToggleSingleLine/ToggleSingleLine.scss';

.content, body {
  background-color: #efefef;
}
.bx--modal-content {
  margin-bottom: 0 !important;
  padding-bottom: 3rem;
}

.important {
  background-color: rgb(255, 228, 109) !important;
  td {
    background: transparent !important;
  }
  &-orange {
    background-color: rgb(255, 164, 46) !important;
  }
  &-green {
    background-color: rgb(135, 245, 135) !important;
  }
}

.override-side-bar-detials {
  flex: none !important;
  h2 {
    font-size: 2rem;
  }
  text-align: center;
  font-size: 1.23rem;
}