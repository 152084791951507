.user-profile-box {
  &__detail {
    text-align: start;
    padding: 1rem;

    h4 {
      font-size: large;
    }

    p {
      font-size: small;
    }
  }

  &__button {
    width: 100%;
  }
}
